.ra-stepmap {
  position: relative;
  max-height: 100%;

  &__zoom {
    position: absolute;
    background-color: var(--color-white);
    border-radius: 50%;
    padding: var(--ra-spacing-1);
    width: 24px !important;
    height: 24px !important;
    top: var(--ra-spacing-2);
    right: var(--ra-spacing-2);
    z-index: 3 !important;

    svg {
      color: var(--color-dark);
    }

    &:hover {
      cursor: pointer;
      background-color: var(--color-primary);
      svg {
        color: var(--color-white);
      }
    }
  }
}

.stepmap_export_container {
  position: relative;
  width: 100% !important;
  height: auto !important;
  max-height: 80% !important;
  z-index: 2;

  > div {
    position: relative !important;
    top: 0 !important;
  }

  img {
    object-fit: contain !important;
    width: auto !important;
    max-width: 100% !important;
    height: 80% !important;
    max-height: 350px !important;
    float: right;

    &:hover {
      cursor: pointer;
    }
    &:focus-visible {
      outline: var(--ra-focus-outline);
      outline-offset: 2px;
    }
  }
}
